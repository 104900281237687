
import { defineComponent, ref, watch } from "vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import SuccessTile from "@/components/atomics/SuccessTile.vue";
import { useForm } from "vee-validate";
import useFormHandler from "@/lib/compositional-logic/useFormHandler";

export default defineComponent({
  name: "TwoFactorQR",
  components: {
    FlatButton,
    ValidationTextField,
    SuccessTile,
  },
  props: {
    secret: {
      type: String,
      default: "XBZ2H7BH17VCIOECYNEM15BTVH556NBG",
    },
    qrImage: {
      type: String,
      default: "",
    },
    verifyMethod: {
      type: Function,
      required: true,
    },
    otherFormValues: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    const isSuccess = ref(false);
    const onSuccess = () => {
      isSuccess.value = true;
      emit("onSuccess");
    };

    const form = useForm({
      initialValues: {
        ...props.otherFormValues,
        totpSecret: props.secret,
        authenticationCode: "",
      },
    });

    watch(
      () => props.secret,
      (val, old) => {
        form.setFieldValue("totpSecret", val);
      }
    );

    const { formHandle } = useFormHandler(form, async () => {
      props
        .verifyMethod(form)
        .then((response: any) => {
          if (response) onSuccess();
        })
        .catch((e: any) => {
          form.setErrors({ authenticationCode: e.data.message });
        });
    });
    const submit = async () => {
      await formHandle();
    };

    return { isSuccess, submit, form };
  },
});
