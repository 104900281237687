
// @ is an alias to /sr
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  onBeforeMount,
  inject,
} from "vue";
import TextButton from "@/components/atomics/TextButton.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import ToggleCheckbox from "@/components/atomics/ToggleCheckbox.vue";
import Popup from "@/components/popups/Popup.vue";
import UpdateAvatarPopup from "@/components/popups/members/UpdateAvatarPopup.vue";
import UpdateInfoPopup from "@/components/popups/members/UpdateInfoPopup.vue";
import UpdatePasswordPopup from "@/components/popups/members/UpdatePasswordPopup.vue";
import BreadScrum from "@/components/atomics/BreadScrum.vue";
import TwoFactorQr from "@/components/organisms/TwoFactorQR.vue";
import TableSelect from "@/components/atomics/TableSelect.vue";
import XIDButton from "@/components/atomics/XIDButton.vue";
import MenuOptionDropdown from "@/components/atomics/MenuOptionDropdown.vue";
import AvatarCircle from "@/components/organisms/AvatarCircle.vue";
import AfterLoginPageLayout from "@/views/layouts/AfterLoginPageLayout.vue";
import defaultAvatar from "@/assets/icons/default-avatar.svg";
import Vue3Popper from "@/components/atomics/Vue3Popper.vue";
import { getFilePathFromUrl } from "@/lib/utility/stringUtil";
import {
  AccountRepository,
  MemberRepository,
  OnboardingRepository,
  RepositoryFactory,
} from "@/lib/https";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
enum editPopupList {
  UPDATE_NAME,
  UPDATE_POSITION,
  UPDATE_PHONE_NUMBER,
  UPDATE_EMAIL,
  UPDATE_PASSWORD,
  UPDATE_LOGO,
}

export default defineComponent({
  name: "AccountDetail",
  components: {
    TextButton,
    ToggleCheckbox,
    Popup,
    FlatButton,
    BreadScrum,
    TwoFactorQr,
    TableSelect,
    XIDButton,
    MenuOptionDropdown,
    UpdateAvatarPopup,
    UpdateInfoPopup,
    AvatarCircle,
    UpdatePasswordPopup,
    AfterLoginPageLayout,
    Vue3Popper,
  },
  setup() {
    const { t } = useI18n();

    const state = reactive({
      isOverflow: false,
      currentSection: "section1",
      showAddAuthPopup: false,
      breadscrumLinks: [
        { text: t("accountDetail.teamMembers"), to: "/team-member" },
        { text: t("accountDetail.memberProfile"), to: "/account" },
      ],
      profile: {
        id: "",
        profilePicture: "",
        lastName: "",
        firstName: "",
        position: "",
        phoneNumber: "",
        email: "",
        emailPending: "",
        role: 0,
        roleName: "",
        totpTurnedOn: false,
        hasXID: false,
        receiveNotification: false,
        canReset2FA: false,
        noPassword: false,
      },
      pictureFile: "",
      selectedUsers: [],
      authUsers: [],

      showAuthTwoFactor: false,

      showChangePasswordPopup: false,
      showEditName: false,

      editEmailState: -1,
      showPopup: false,
      selectedEditPopup: -1,
      userApproversLength: 0,
      showEmailOption: false,
    });

    const isMobile = inject("isMobile");
    const groups = ref();
    const userApprovers = ref();
    const addApproversList = ref();
    const approverData = ref();

    const store = useStore();
    const accountInfo = store.getters["header/getAccountInfo"];

    const totpSecret = ref("");
    const qrCode = ref("");

    const showSuccessNotification = (text: string) =>
      store.commit("notification/showSuccessNotification", text);
    const showErrorNotification = (text: string) =>
      store.commit("notification/showErrorNotification", text);

    const { verifyTOTPSecret, turnOnTOTPSecret, turnOffTOTPSecret } =
      RepositoryFactory.getRepository<OnboardingRepository>(
        OnboardingRepository
      );

    const onShowTwoFactor = async () => {
      if (state.profile.totpTurnedOn) {
        await turnOffTOTPSecret().catch((e) => {
          showErrorNotification(e.data.message);
          return;
        });
        state.profile.totpTurnedOn = false;
      } else {
        const values = await turnOnTOTPSecret().catch((e) => {
          showErrorNotification(e.data.message);
          return;
        });
        totpSecret.value = values.secret;
        qrCode.value = "data:image/png;base64, " + values.qrCode;

        state.showAuthTwoFactor = true;
      }
    };

    const onTurnOffTwoFactor = async () => {
      await turnOffTOTPSecret().catch((e) => {
        showErrorNotification(e.data.message);
        return;
      });
      state.showAuthTwoFactor = false;
    };

    const {
      getFullProfile,
      updateReceiveNotification,
      addApprovers,
      deleteApprovers,
      resendPendingEmail,
      cancelEmailChanging,
      linkXIDAccount,
    } = RepositoryFactory.getRepository<AccountRepository>(AccountRepository);

    const { getApproversList } =
      RepositoryFactory.getRepository<MemberRepository>(MemberRepository);

    // Receive Notification
    const editReceiveNotification = async (payload: any) => {
      const formData = new FormData();
      formData.append("receiveNotification", payload);
      const response = await updateReceiveNotification(formData);
      if (response) {
        const requiredResponse = {
          receiveNotification: response.receiveNotification,
        };
        handleSubmitChangeField(requiredResponse);
      }
    };

    const hideEditPopup = () => {
      state.selectedEditPopup = -1;
      state.showPopup = false;
    };

    const handleSubmitChangeField = ({ id, ...response }: any) => {
      if (response.errorMessage) {
        showErrorNotification(response.errorMessage);
      } else {
        state.profile = {
          ...state.profile,
          ...response,
        };
        showSuccessNotification(t("notification.updateSuccess"));
      }
    };

    const addApproverSuccess = () => {
      state.showAddAuthPopup = false;

      userApprovers.value = approverData.value;
      state.userApproversLength = userApprovers.value.length;
      showSuccessNotification(t("notification.createSuccess"));
    };

    const { addApproversForm } = formHandler(
      approverData,
      addApprovers,
      addApproverSuccess
    );

    const addUserApprovers = () => {
      if (approverData.value) {
        addApproversForm(state.profile.id);
      }
    };

    // Delete approver
    const deleteApprover = async (approver: any) => {
      await deleteApprovers(approver.id)
        .then((result) => {
          if (result) {
            userApprovers.value.splice(
              userApprovers.value.indexOf(approver),
              1
            );
            state.userApproversLength--;
            showSuccessNotification(t("notification.deleteSuccess"));
          }
        })
        .catch((e) => {
          showErrorNotification(e.data.message);
        });
    };

    const cancelPendingEmail = async () => {
      const result = await cancelEmailChanging();
      if (result) {
        state.profile.emailPending = "";
      }
    };

    const resendPendingEmailClick = async () => {
      const result = await resendPendingEmail();
      if (result) {
        showSuccessNotification(t("notification.resendEmailSuccess"));
      }
    };

    const searchKeyword = ref("");
    let page = 1;
    let delay: ReturnType<typeof setTimeout>;

    const approverSearch = (searchKeyword: string) => {
      if (delay) clearTimeout(delay);
      delay = setTimeout(async () => {
        const payload = await getApproversList(
          (page = 1),
          100,
          searchKeyword,
          ""
        );
        addApproversList.value = payload.data;
      }, 300);
    };

    onBeforeMount(async () => {
      const profilePayload = await getFullProfile();
      if (profilePayload) {
        state.profile = profilePayload;
        // state.profile.profilePicture = profilePayload.profilePicture
        //   ? process.env.VUE_APP_API_CLOUD_URL + profilePayload.profilePicture
        //   : "";
        state.profile.profilePicture = accountInfo.avatar;
        groups.value = profilePayload.groups;
        userApprovers.value = profilePayload.approvers;
        state.userApproversLength = profilePayload.approvers.length;
        const approverPayload = await getApproversList(
          1,
          100,
          "",
          ""
        );
        if (approverPayload) {
          addApproversList.value = approverPayload.data;
        }
        // Revert Bug_No.1784
        // if (!state.profile.canReset2FA) {
        //   state.profile.totpTurnedOn = false;
        // }
      }
    });

    const onClickLinkXIDAccount = async () => {
      await linkXIDAccount();
    };

    return {
      ...toRefs(state),
      isMobile,
      groups,
      userApprovers,
      addApproversList,
      editPopupList,
      editReceiveNotification,
      hideEditPopup,
      handleSubmitChangeField,
      addUserApprovers,
      addApproversForm,
      approverData,
      deleteApprover,
      approverSearch,
      resendPendingEmail,
      cancelPendingEmail,
      verifyTOTPSecret,
      onShowTwoFactor,
      onTurnOffTwoFactor,
      totpSecret,
      qrCode,
      onClickLinkXIDAccount,
      accountInfo,
      resendPendingEmailClick,
      defaultAvatar,
      getFilePathFromUrl,
    };
  },
  computed: {
    getLogoData(): any {
      if (this.selectedEditPopup === editPopupList.UPDATE_LOGO)
        return this.accountInfo.avatar;
      return defaultAvatar;
    },
  },
  methods: {
    scrollToSection(sectionName: string) {
      const el = this.$refs[sectionName] as HTMLElement;
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
        this.currentSection = sectionName;
      }
    },

    updateSelectedApprover(payload: any) {
      this.approverData = payload;
    },

    showEditPopup(selectedEditPopup: number) {
      this.selectedEditPopup = selectedEditPopup;
      this.showPopup = true;
    },

    addAuthUser() {
      this.selectedUsers.forEach((user) => {
        if (!this.authUsers.includes(user)) this.authUsers.push(user);
      });
      this.showAddAuthPopup = false;
    },

    showTwoFactorAuth(isShow: boolean) {
      this.showAuthTwoFactor = isShow;
      this.onShowTwoFactor();
    },
    onSuccessAuthTwoFactor() {
      this.showAuthTwoFactor = false;
      this.profile.totpTurnedOn = true;
    },
    openURL(url: string) {
      window.open(url, "_blank");
    },
    handleScroll(e: any) {
      if (e.target.scrollTop == 0) {
        this.isOverflow = false;
      } else {
        this.isOverflow = true;
      }
    },
  },
});

// Add new approvers
const formHandler = (
  approverData: any,
  onSubmit: CallableFunction,
  onSuccess: CallableFunction
) => {
  const addApproversForm = async (id: string) => {
    const approverList = [] as never[];
    approverData.value.forEach((element: any) => {
      approverList.push(element.id as never);
    });

    const data = {
      approvers: approverList,
    };

    if (await onSubmit(id, data)) {
      onSuccess();
    }
  };

  return { addApproversForm };
};
