import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "two-factor-body" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "code" }
const _hoisted_5 = { class: "secret-title" }
const _hoisted_6 = { class: "secret-code" }
const _hoisted_7 = { class: "hint-div" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "xid-install" }
const _hoisted_10 = {
  href: "https://apps.apple.com/us/app/google-authenticator/id388497605",
  target: "_blank",
  class: "mr-2"
}
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  href: "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
  target: "_blank",
  class: "google-img ml-2"
}
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "mt-5 second-detail" }
const _hoisted_15 = {
  key: 0,
  class: "field-group"
}
const _hoisted_16 = { class: "code-input field-input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidationTextField = _resolveComponent("ValidationTextField")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_success_tile = _resolveComponent("success-tile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: 
          _ctx.qrImage !== ''
            ? _ctx.qrImage
            : require('@/assets/images/qrcode-sample.svg')
        ,
        class: "qr-code"
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('onboarding.confirmLabelCode')), 1),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.secret), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("p", {
          innerHTML: _ctx.$t('onboarding.confirmDetail1')
        }, null, 8, _hoisted_8),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("a", _hoisted_10, [
            _createElementVNode("img", {
              src: require('icons/app_store.svg'),
              alt: "app store image",
              class: "app-store-img"
            }, null, 8, _hoisted_11)
          ]),
          _createElementVNode("a", _hoisted_12, [
            _createElementVNode("img", {
              src: require('icons/google_play.svg'),
              alt: "google play image",
              class: "google-play-img"
            }, null, 8, _hoisted_13)
          ])
        ]),
        _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$t("onboarding.confirmDetail2")), 1)
      ]),
      (!_ctx.isSuccess)
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createVNode(_component_ValidationTextField, {
                class: "code-input",
                placeholder: _ctx.$t('onboarding.confirmCodeHint'),
                "keep-error-space": false,
                rules: "required",
                message: {
              error_required: _ctx.$t('errors.required'),
            },
                name: "authenticationCode"
              }, null, 8, ["placeholder", "message"])
            ]),
            _createVNode(_component_FlatButton, {
              onOnClick: _ctx.submit,
              enabled: _ctx.form.values.authenticationCode.length > 5,
              text: _ctx.$t('onboarding.confirmValidateButtonText'),
              class: "confirm-btn"
            }, null, 8, ["onOnClick", "enabled", "text"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.isSuccess)
        ? (_openBlock(), _createBlock(_component_success_tile, {
            key: 1,
            class: "success-noti",
            "can-close": false,
            "auto-close": false,
            text: _ctx.$t('onboarding.confirmSuccess')
          }, null, 8, ["text"]))
        : _createCommentVNode("", true)
    ])
  ]))
}